import { CAUserDto } from './../../models/administration/user/CAUser.dto';
import { AttachedDataValueDto } from './../../models/administration/attached-data/attached-data-value.dto';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Store, Select } from '@ngxs/store';
import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { environment } from 'src/environments/environment';
import { ConversationListRequestDto } from 'src/core/models/conversation/conversation-list-request.dto';
import { ConversationListResponseDto } from 'src/core/models/conversation/conversation-list-response.dto';
import { ConversationModuleState } from 'src/core/states/conversation/conversation-module.state';
import { ConversationModuleStateModel } from 'src/core/models/conversation/conversation-module.state-model';
import {
  DataChange,
  FilterChange,
} from 'src/core/actions/conversation/conversation-module.actions';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { ConversationListType } from 'src/core/models/generic-lookup-type/conversation/conversation-list-type.glt';
import { Operators } from 'src/core/models/request/operator.enum';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { ConversationCommentInputDto } from 'src/core/models/comment/comment-input.dto';
import { ConversationCommentDto } from 'src/core/models/comment/comment.dto';
import { ConversationCategoryDto } from 'src/core/models/category/conversation-category.dto';
import { ConversationTagDto } from 'src/core/models/conversation/tag/conversation-tag.dto';
import { ConversationType } from 'src/core/models/generic-lookup-type/conversation/conversation-type.glt';
import { DepartmentDto } from 'src/core/models/administration/department/department.dto';
import { NotSuitableForEvaluationReasonDto } from 'src/core/models/quality-management/not-suitable-for-evaluation-reason-dto';
import { DepartmentFilterModeOption } from 'src/core/models/generic-lookup-type/department/department-filter-mode-option.glt';
import { AgentListFilterModeOption } from 'src/core/models/generic-lookup-type/identity/agent-list-filter-mode-option.glt';
import { CacheOptionChange } from 'src/core/actions/conversation/conversation-module.actions';
import { MarkAsNotSuitableForEvaluationRequestDto } from 'src/core/models/quality-management/evaluation/mark-as-not-suitable-for-evaluation-request.dto';
import { ConversationTopicDto } from 'src/core/models/conversation/ai-topic/conversation-topic.dto';
import { TopicDto } from 'src/core/models/conversation/ai-topic/topic.dto';
import { DefaultAcousticParameterValuesDto } from 'src/core/models/conversation/default-acoustic-parameter-values.dto';
import { TagDto } from 'src/core/models/conversation/tag/tag.dto';
import { ConversationTranscriptDto } from 'src/core/models/conversation/transcript/conversation-transcript.dto';
import { ConversationCleanerFilterDto } from 'src/core/models/conversation/cleaner/conversation-cleaner-filter.dto';
import { AttachedDataFilterDto } from 'src/core/models/administration/attached-data/attached-data-filter.dto';
import { ExcelType } from 'src/core/models/enum/excel-type.enum';
import { FeatureService } from '../feature/feature.service';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { ConversationsResultInputDto } from 'src/core/models/conversation/conversations-result-input.dto';
import { ConversationsResultOutputDto } from 'src/core/models/conversation/conversations-result-output.dto';
import { ReanalyzeConversationRequestDto } from 'src/core/models/conversation/reanalyze/reanalyze-conversation-request.dto';
import { DecimalPipe } from '@angular/common';
import { MatTableColumnDefinitionModel } from 'src/core/models/mat-table/mat-table-column-definition.model';
import { ConversationStateMachineStates } from 'src/core/models/conversation/conversation-state-machine-states.dto';
import { CategoryType } from 'src/core/models/enum/category-type.enum';
import { ConversationSummaryDto } from 'src/core/models/conversation/conversation-summary.dto';
import { ConversationExcelDetailDto } from 'src/core/models/conversation/conversation-excel-detail.dto';
import { ConversationSide } from 'src/core/models/generic-lookup-type/conversation/conversation-side.glt';

@Injectable({
  providedIn: 'root',
})
export class ConversationService {
  @Select(ConversationModuleState.dataRequested)
  dataRequested$: Observable<boolean>;

  @Select(ConversationModuleState.getCacheOption)
  cacheOption$: Observable<boolean>;

  filterRelatedGroupId: BehaviorSubject<string> = new BehaviorSubject(null);

  getCachedData = false;
  cachedConversationList: ConversationListResponseDto;

  private apiBase = environment.apis.default.url;
  private pageSize = 0;
  private requestSubscription: Subscription = null;

  isDrillDownResult: boolean = false;
  drillDownResultFilters: FilterItemDto[] = [];
  filters: FilterItemDto[] = [];
  tempQueryId: number = null;

  constructor(
    private http: HttpClient,
    private store: Store,
    private operators: Operators,
    private config: ConfigStateService,
    private localizationService: LocalizationService,
    private featureService: FeatureService
  ) {
    this.pageSize = 25;

    this.cacheOption$.subscribe(result => {
      this.getCachedData = result;
    });

    this.dataRequested$.subscribe(dataRequested => {
      if (dataRequested) {
        this.loadFromState();
      }
    });
  }

  private createFetchCommentsRequest(id: number): Observable<ConversationCommentDto[]> {
    return this.http.get(`${this.apiBase}/api/app/conversation/${id}/comment`) as Observable<
      ConversationCommentDto[]
    >;
  }

  private createFetchAttachedDataRequest(id: number): Observable<AttachedDataValueDto[]> {
    return this.http.get(`${this.apiBase}/api/app/conversation/${id}/attached-data`) as Observable<
      AttachedDataValueDto[]
    >;
  }

  get(request: ConversationListRequestDto): Observable<ConversationListResponseDto> {
    let params = new HttpParams();

    params = params.append('filters', JSON.stringify(request.filters));
    params = params.append('sorters', JSON.stringify(request.sorters));
    params = params.append('skipCount', JSON.stringify(request.skipCount));
    params = params.append('maxResultCount', JSON.stringify(request.maxResultCount));

    return this.http.get(`${this.apiBase}/api/app/conversation`, {
      params,
    }) as Observable<ConversationListResponseDto>;
  }

  getWithCategories(request: ConversationListRequestDto): Observable<ConversationListResponseDto> {
    let params = new HttpParams();

    params = params.append('filters', JSON.stringify(request.filters));
    params = params.append('sorters', JSON.stringify(request.sorters));
    params = params.append('skipCount', JSON.stringify(request.skipCount));
    params = params.append('maxResultCount', JSON.stringify(request.maxResultCount));

    return this.http.get(`${this.apiBase}/api/app/conversation/with-categories`, {
      params,
    }) as Observable<ConversationListResponseDto>;
  }

  getById(id: number): Observable<ConversationDto> {
    return this.http.get(`${this.apiBase}/api/app/conversation/` + id, {
      headers: { 'redirect-not-found-page': 'true' },
    }) as Observable<ConversationDto>;
  }

  setFilters(filters: FilterItemDto[], state: ConversationModuleStateModel): FilterItemDto[] {
    const filterFormValues = state.filterFormValues;

    filters = this.setQuickSearchTermFilter(filterFormValues, filters);
    filters = this.setConversationTypeFilter(filterFormValues, filters);
    filters = this.setQueryFilter(filterFormValues, filters);
    filters = this.setDateRangeFilter(filterFormValues, filters);
    filters = this.setDepartmentFilter(filterFormValues, filters);
    filters = this.setAgentFilter(filterFormValues, filters);
    filters = this.setCallFilters(filterFormValues, filters);
    filters = this.setChatFilters(filterFormValues, filters);
    filters = this.setEvaluationFilters(filterFormValues, filters);
    filters = this.setTopicFilter(filterFormValues, filters);
    filters = this.setTagFilter(filterFormValues, filters);
    filters = this.setCategoryFilter(filterFormValues, filters);
    filters = this.setAttachedDataFilter(filterFormValues, filters);
    filters = this.setAttachedDataListFilter(filterFormValues, filters);
    filters = this.setOthersFilters(filterFormValues, filters);

    return filters;
  }

  setSorters(state: ConversationModuleStateModel): SorterItemDto[] {
    const result: SorterItemDto[] = [];

    result.push({
      field: this.store.selectSnapshot(ConversationModuleState.getSortField),
      direction:
        this.store.selectSnapshot(ConversationModuleState.getSortDirection) === 'desc'
          ? 'DESC'
          : 'ASC',
    });

    return result;
  }

  iterateFormValues(obj, filters: FilterItemDto[]) {
    if (!obj) {
      return;
    }

    Object.keys(obj).forEach(key => {
      const valueGroup = obj[key];
      const valueList: string[] = [];
      if (valueGroup && valueGroup.operator > -1 && valueGroup.field && valueGroup.value) {
        if (
          Array.isArray(valueGroup.value) &&
          valueGroup.value.length > 0 &&
          typeof valueGroup.value[0] === 'object' &&
          valueGroup.value[0].id
        ) {
          valueGroup.value.forEach(v => {
            valueList.push(v.id);
          });
          filters.push({
            field: valueGroup.field,
            operator: valueGroup.operator,
            value: valueList,
          });
        } else if (
          !Array.isArray(valueGroup.value) ||
          (Array.isArray(valueGroup.value) && valueGroup.value.length > 0)
        ) {
          filters.push({
            field: valueGroup.field,
            operator: valueGroup.operator,
            value: valueGroup.value,
          });
        }
      } else if (typeof valueGroup === 'object') {
        this.iterateFormValues(valueGroup, filters);
      }
    });
  }

  loadFromState() {
    const state = this.store.selectSnapshot<ConversationModuleStateModel>(ConversationModuleState);

    if (this.getCachedData && this.cachedConversationList) {
      const dataChangeAction = new DataChange(this.cachedConversationList);
      this.store.dispatch(dataChangeAction);

      // Reset cache option to false
      const cacheOptionChangeAction = new CacheOptionChange(false);
      this.store.dispatch(cacheOptionChangeAction);

      return;
    }

    this.filters = [];
    let filters = this.isDrillDownResult
      ? (this.filters = this.drillDownResultFilters)
      : this.setFilters(this.filters, state);

    if (this.tempQueryId > 0) {
      const currentQuery = filters.find(x => x.field == 'query');

      if (currentQuery && Array.isArray(currentQuery.value)) {
        currentQuery.value.push(this.tempQueryId);
      } else {
        filters.push({
          field: 'query',
          operator: this.operators.Contains,
          value: [this.tempQueryId],
        });
      }

      const qS = filters.find(x => x.field == 'quickSearchTerm')?.value;

      if (
        qS &&
        (qS.indexOf('" near "') >= 0 ||
          qS.indexOf('not "') >= 0 ||
          qS.indexOf('cu:') >= 0 ||
          qS.indexOf('ag:') >= 0)
      ) {
        filters = filters.filter(x => x.field != 'quickSearchTerm');
      }
    }

    const sorters = this.setSorters(state);

    const requestOptions: ConversationListRequestDto = {
      filters,
      sorters,
      maxResultCount: this.pageSize,
      skipCount: state.currentPage * this.pageSize,
    };

    if (this.requestSubscription != null) {
      this.requestSubscription.unsubscribe();
    }

    this.requestSubscription = this.get(requestOptions)
      .pipe(take(1))
      .subscribe({
        next: response => {
          this.cachedConversationList = response;

          const action = new DataChange(response);
          this.store.dispatch(action);
        },
        error: () => {
          const data: ConversationListResponseDto = {
            items: [],
            totalCount: 0,
          };
          this.cachedConversationList = data;
          const action = new DataChange(data);
          this.store.dispatch(action);
        },
      });
  }

  getCategoriesByConversationId(id: number): Observable<ConversationCategoryDto[]> {
    return this.http.get(`${this.apiBase}/api/app/conversation/${id}/category`) as Observable<
      ConversationCategoryDto[]
    >;
  }

  getSummary(id: number): Observable<ConversationSummaryDto> {
    return this.http.get(
      `${this.apiBase}/api/app/conversation/${id}/transcript/summary`
    ) as Observable<ConversationSummaryDto>;
  }

  generateSummary(id: number): Observable<ConversationSummaryDto> {
    return this.http.post(
      `${this.apiBase}/api/app/conversation/${id}/transcript/summary`,
      null
    ) as Observable<ConversationSummaryDto>;
  }

  getTopicsByConversationId(id: number): Observable<ConversationTopicDto[]> {
    return this.http.get(`${this.apiBase}/api/app/conversation/${id}/topic`) as Observable<
      ConversationTopicDto[]
    >;
  }

  getQueryByConversationId(id: number, queryId: number): Observable<ConversationCategoryDto> {
    if (queryId === 0) {
      return of(null);
    } else {
      return this.http.get(
        `${this.apiBase}/api/app/conversation/${id}/category/by-query/${queryId}`
      ) as Observable<ConversationCategoryDto>;
    }
  }

  getCommentsByConversationId(id: number): Observable<ConversationCommentDto[]> {
    return this.createFetchCommentsRequest(id);
  }

  getAttachedDataByConversationId(id: number): Observable<AttachedDataValueDto[]> {
    return this.createFetchAttachedDataRequest(id);
  }

  editTranscript(transcript: ConversationTranscriptDto): Observable<any[]> {
    return this.http.put(
      `${this.apiBase}/api/app/conversation/transcript`,
      transcript
    ) as Observable<any[]>;
  }

  getTranscriptWithRedaction(
    id: number,
    hideRedactedData: boolean
  ): Observable<ConversationTranscriptDto> {
    return this.http.get(
      `${this.apiBase}/api/app/conversation/${id}/transcript-redaction?hideRedactedData=${hideRedactedData}`
    ) as Observable<ConversationTranscriptDto>;
  }

  addComment(comment: ConversationCommentInputDto): Observable<ConversationCommentDto> {
    return this.http
      .post(`${this.apiBase}/api/app/conversation/${comment.conversationId}/comment`, comment)
      .pipe(
        map((res: any) => {
          return res;
        })
      ) as Observable<ConversationCommentDto>;
  }

  deleteComment(id: number, commentId: number): Observable<any[]> {
    return this.http.delete(`${this.apiBase}/api/app/conversation/${id}/comment/${commentId}`).pipe(
      map((res: any) => {
        return res;
      })
    ) as Observable<any[]>;
  }

  indexConversation(id: number) {
    return this.http.put(`${this.apiBase}/api/app/conversation/${id}/index`, null);
  }

  checkConversationById(id: number): Observable<boolean> {
    return this.http.get(`${this.apiBase}/api/app/conversation/${id}/check`) as Observable<boolean>;
  }

  assignTags(id: number, tags: ConversationTagDto[]) {
    return this.http.put(`${this.apiBase}/api/app/conversation/${id}/tag`, tags);
  }

  getConversationTypeByListType(conversationListTypeId: number) {
    if (conversationListTypeId == ConversationListType.call) {
      return ConversationType.call;
    } else if (conversationListTypeId == ConversationListType.chat) {
      return ConversationType.chat;
    } else {
      return ConversationType.all;
    }
  }

  convertConversationListFiltersToTypeFilters(conversationListTypeIds: number[]) {
    let result: number[] = [];

    conversationListTypeIds.forEach(typeId => {
      result.push(this.getConversationTypeByListType(typeId));
    });

    result.push(ConversationType.all);
    return result;
  }

  exportList(excelDetail: ConversationExcelDetailDto): Observable<any> {
    const state = this.store.selectSnapshot<ConversationModuleStateModel>(ConversationModuleState);

    this.filters = [];
    let filters = this.setFilters(this.filters, state);

    filters.push({
      field: 'excelType',
      operator: this.operators.Equals,
      value: ExcelType.Conversation,
    });

    const sorters = this.setSorters(state);

    const requestOptions: ConversationListRequestDto = {
      filters,
      sorters,
      maxResultCount: this.pageSize,
      skipCount: state.currentPage * this.pageSize,
    };

    let params = new HttpParams();

    params = params.append('filters', JSON.stringify(requestOptions.filters));
    params = params.append('sorters', JSON.stringify(requestOptions.sorters));
    params = params.append('excelDetail', JSON.stringify(excelDetail));

    const resultId = this.getResultIdFromUrl(window.location.href);
    if (resultId) {
      params = params.append('resultId', resultId);
    }

    return this.http.get(`${this.apiBase}/api/app/excel/export/`, {
      params,
    }) as Observable<any>;
  }

  private getResultIdFromUrl(url: string): string | null {
    const resultPattern = /\/result\/([A-Za-z0-9]+)/;
    const match = url.match(resultPattern);
    return match ? match[1] : null;
  }

  private setQuickSearchTermFilter(values: any, filters: FilterItemDto[]): FilterItemDto[] {
    if (
      values.quickSearchTerm?.quickSearchText &&
      values.quickSearchTerm?.quickSearchText.trim().length > 0
    ) {
      const quickSearchTerm = values.quickSearchTerm?.quickSearchText.trim();
      const externalIdFilterLabel = this.localizationService.instant('::FilterByExtIdLabel').trim();
      const agentLabel = this.localizationService.instant('::FilterByAgentLabel').trim();
      const customerLabel = this.localizationService.instant('::FilterByCustomerLabel').trim();
      if (quickSearchTerm.startsWith(externalIdFilterLabel)) {
        const externalIdFilterText = quickSearchTerm.substr(externalIdFilterLabel.length).trim();
        if (externalIdFilterText?.length > 0) {
          filters.push({
            field: 'externalId',
            operator: this.operators.Equals,
            value: externalIdFilterText,
          });
        }
      } else {
        filters.push({
          field: 'quickSearchTerm',
          operator: this.operators.Equals,
          value: quickSearchTerm,
        });
      }

      if (quickSearchTerm.startsWith(agentLabel) || quickSearchTerm.startsWith(customerLabel)) {
        filters.push({
          field: 'quickSearchTermSideId',
          operator: this.operators.Equals,
          value: ConversationSide.all,
        });
      } else {
        filters.push({
          field: 'quickSearchTermSideId',
          operator: this.operators.Equals,
          value: values.quickSearchTerm?.sideId,
        });
      }
    }

    return filters;
  }

  private setConversationTypeFilter(values: any, filters: FilterItemDto[]): FilterItemDto[] {
    if (values.conversationListType && values.conversationListType.length > 0) {
      filters.push({
        field: 'conversationListType',
        operator: this.operators.In,
        value: values.conversationListType,
      });
    }

    return filters;
  }

  private setQueryFilter(values: any, filters: FilterItemDto[]): FilterItemDto[] {
    if (values.query && values.query.length > 0) {
      filters.push({
        field: 'query',
        operator: this.operators.Equals,
        value: [values.query[0].id],
      });
    }

    return filters;
  }

  private setTopicFilter(values: any, filters: FilterItemDto[]): FilterItemDto[] {
    const ids: number[] = [];
    for (let i = 0; i < values.topic?.length; i++) {
      const topic = values.topic[i] as TopicDto;
      ids.push(topic.id);
    }
    if (ids.length > 0) {
      filters.push({
        field: 'topic',
        operator: this.operators.Equals,
        value: ids,
      });
    }

    return filters;
  }

  private setCategoryFilter(values: any, filters: FilterItemDto[]): FilterItemDto[] {
    if (values.category.category && values.category.category.length > 0) {
      if (values.category.category[0].categoryType == CategoryType.Uncategorized) {
        filters.push({
          field: 'uncategorized',
          operator: this.operators.Equals,
          value: ['uncategorized'],
        });
      }
      if (values.category.category[0].categoryType == CategoryType.Query) {
        filters.push({
          field: 'query',
          operator: this.operators.Equals,
          value: [values.category.category[0].queryId],
        });
      }
      if (values.category.category[0].categoryType == CategoryType.Topic) {
        filters.push({
          field: 'topic',
          operator: this.operators.Equals,
          value: [values.category.category[0].topicId],
        });
      }
    }

    if (values.category?.categoryMatchType > 0) {
      filters.push({
        field: 'categoryMatchType',
        operator: this.operators.Equals,
        value: values.category.categoryMatchType,
      });
    }

    return filters;
  }

  private setTagFilter(values: any, filters: FilterItemDto[]): FilterItemDto[] {
    const ids: number[] = [];
    for (let i = 0; i < values.tag?.length; i++) {
      const tag = values.tag[i] as TagDto;
      ids.push(tag.id);
    }
    if (ids.length > 0) {
      filters.push({
        field: 'tag',
        operator: this.operators.Equals,
        value: ids,
      });
    }

    return filters;
  }

  private setAttachedDataFilter(values: any, filters: FilterItemDto[]): FilterItemDto[] {
    const attachedData = values.attachedData as AttachedDataFilterDto;
    const attachedDataInOthers = values.others?.attachedData as AttachedDataFilterDto;
    if (attachedData != null || attachedDataInOthers != null) {
      filters.push({
        field: 'attachedData',
        operator: this.operators.Equals,
        value: attachedData ?? attachedDataInOthers,
      });
    }
    return filters;
  }

  private setAttachedDataListFilter(values: any, filters: FilterItemDto[]): FilterItemDto[] {
    const attachedDataList = {
      filters: values.attachedDataMultiple.filters.filter(f => f.value !== ''),
    };
    if (attachedDataList && attachedDataList.filters.length > 0) {
      filters.push({
        field: 'attachedDataList',
        operator: this.operators.Contains,
        value: attachedDataList,
      });
    }
    return filters;
  }

  private setDateRangeFilter(values: any, filters: FilterItemDto[]): FilterItemDto[] {
    if (values.date) {
      filters.push({
        field: 'dateRange',
        operator: this.operators.Equals,
        value: {
          period: values.date.shortcut,
          dateFrom: values.date.start,
          dateTo: values.date.end,
        },
      });
    }

    return filters;
  }

  private setDepartmentFilter(values: any, filters: FilterItemDto[]): FilterItemDto[] {
    if (values.department.selectedDepartments && values.department.selectedDepartments.length > 0) {
      const ids: number[] = [];
      let departmentOperator = this.operators.In;
      if (
        Number(values.department.filterModeId) ==
        DepartmentFilterModeOption.AllDepartmentsExceptSelectedDeparment
      ) {
        departmentOperator = this.operators.NotContains;
      }

      for (let i = 0; i < values.department.selectedDepartments.length; i++) {
        const department = values.department.selectedDepartments[i] as DepartmentDto;

        ids.push(department.id);
      }

      filters.push({
        field: 'departmentId',
        operator: departmentOperator,
        value: ids,
      });
    }

    return filters;
  }

  private setAgentFilter(values: any, filters: FilterItemDto[]): FilterItemDto[] {
    if (values.user && values.user.selectedUsers?.length > 0) {
      const ids: string[] = [];
      let userOperator = this.operators.In;
      if (
        Number(values.user.filterModeId) == AgentListFilterModeOption.AllAgentsExceptSelectedAgent
      ) {
        userOperator = this.operators.NotContains;
      }

      for (let i = 0; i < values.user.selectedUsers.length; i++) {
        const user = values.user.selectedUsers[i] as CAUserDto;

        ids.push(user.id);
      }

      filters.push({
        field: 'userId',
        operator: userOperator,
        value: ids,
      });
    }

    return filters;
  }

  private setCallFilters(values: any, filters: FilterItemDto[]): FilterItemDto[] {
    for (const prop in values.call) {
      if (values.call.hasOwnProperty(prop)) {
        const val = values.call[prop];

        if (typeof val === 'string' && val.trim().length > 0) {
          filters.push({
            field: 'call.' + prop,
            operator: this.operators.Equals,
            value: val,
          });
        } else if (typeof val === 'number' && val > 0) {
          filters.push({
            field: 'call.' + prop,
            operator: this.operators.Equals,
            value: val,
          });
        } else if (
          typeof val === 'object' &&
          prop == 'holdCount' &&
          (values.call.holdCount.editorLeftValue > 0 || values.call.holdCount.editorRightValue > 0)
        ) {
          filters.push({
            field: 'call.' + prop,
            operator: this.operators.Equals,
            value: {
              leftValue: values.call.holdCount.editorLeftValue,
              rightValue: values.call.holdCount.editorRightValue,
            },
          });
        } else if (
          typeof val === 'object' &&
          prop == 'holdDurationSec' &&
          (values.call.holdDurationSec.editorLeftValue > 0 ||
            values.call.holdDurationSec.editorRightValue > 0)
        ) {
          filters.push({
            field: 'call.' + prop,
            operator: this.operators.Equals,
            value: {
              leftValue: values.call.holdDurationSec.editorLeftValue,
              rightValue: values.call.holdDurationSec.editorRightValue,
            },
          });
        } else if (val && val.length > 0) {
          filters.push({
            field: 'call.' + prop,
            operator: this.operators.In,
            value: val,
          });
        }
      }
    }

    return filters;
  }

  private setChatFilters(values: any, filters: FilterItemDto[]): FilterItemDto[] {
    for (const prop in values.chat) {
      if (values.chat.hasOwnProperty(prop)) {
        const val = values.chat[prop];

        if (typeof val === 'string' && val.trim().length > 0) {
          filters.push({
            field: 'chat.' + prop,
            operator: this.operators.Equals,
            value: val,
          });
        } else if (typeof val === 'number' && val > 0) {
          filters.push({
            field: 'chat.' + prop,
            operator: this.operators.Equals,
            value: val,
          });
        }
      }
    }

    return filters;
  }

  private setOthersFilters(values: any, filters: FilterItemDto[]): FilterItemDto[] {
    for (const prop in values.others) {
      if (values.others.hasOwnProperty(prop)) {
        const val = values.others[prop];

        if (typeof val === 'string' && val.trim().length > 0) {
          filters.push({
            field: 'others.' + prop,
            operator: this.operators.Equals,
            value: val,
          });
        } else if (typeof val === 'number' && val > 0) {
          filters.push({
            field: 'others.' + prop,
            operator: this.operators.GreaterThanOrEquals,
            value: val,
          });
        } else if (prop == 'duration') {
          filters.push({
            field: 'others.' + prop,
            operator: val.operator,
            value: val.editorValue,
          });
        } else if (prop == 'transferCount') {
          filters.push({
            field: 'others.' + prop,
            operator: val.operator,
            value: val.editorValue,
          });
        } else if (prop == 'ratingForNPS' && val.editorValue && val.editorValue > 0) {
          filters.push({
            field: 'others.' + prop,
            operator: val.operator,
            value: val.editorValue,
          });
        } else if (prop == 'ratingForCSAT' && val.editorValue && val.editorValue > 0) {
          filters.push({
            field: 'others.' + prop,
            operator: val.operator,
            value: val.editorValue,
          });
        }
      }
    }

    return filters;
  }

  private setEvaluationFilters(values: any, filters: FilterItemDto[]): FilterItemDto[] {
    for (const prop in values.evaluation) {
      if (values.evaluation.hasOwnProperty(prop)) {
        const val = values.evaluation[prop];

        if (typeof val === 'string' && val.trim().length > 0) {
          filters.push({
            field: 'evaluation.' + prop,
            operator: this.operators.Equals,
            value: val,
          });
        } else if (typeof val === 'number' && val > 0) {
          filters.push({
            field: 'evaluation.' + prop,
            operator: this.operators.Equals,
            value: val,
          });
        }
      }
    }

    return filters;
  }

  markAsSuitableForEvaluation(id: number) {
    return this.http.put(
      `${this.apiBase}/api/app/conversation/${id}/mark-as-suitable-for-evaluation/`,
      null
    );
  }

  markAsNotSuitableForEvaluation(
    id: number,
    evaluationId: number | null,
    assignmentId: number | null,
    reasonList: NotSuitableForEvaluationReasonDto[]
  ) {
    let param = new MarkAsNotSuitableForEvaluationRequestDto();
    param.assignmentId = assignmentId;
    param.reasonList = reasonList;
    param.evaluationId = evaluationId;
    return this.http.put(
      `${this.apiBase}/api/app/conversation/${id}/mark-as-not-suitable-for-evaluation/`,
      param
    );
  }

  markAsPriorForEvaluation(id: number) {
    return this.http.put(
      `${this.apiBase}/api/app/conversation/${id}/mark-as-prior-for-evaluation/`,
      null
    );
  }

  clearMark(id: number) {
    return this.http.put(`${this.apiBase}/api/app/conversation/${id}/clear-mark/`, null);
  }

  getDefaultAcousticParameterValueForForm(
    acousticParamerTypeId: number
  ): Observable<DefaultAcousticParameterValuesDto> {
    return this.http.post(
      `${this.apiBase}/api/app/conversation/${acousticParamerTypeId}/default-acoustic-parameter-value-for-form`,
      null
    ) as Observable<DefaultAcousticParameterValuesDto>;
  }

  getDefaultAcousticParameterValueForQuery(
    filterableField: number
  ): Observable<DefaultAcousticParameterValuesDto> {
    return this.http.post(
      `${this.apiBase}/api/app/conversation/${filterableField}/default-acoustic-parameter-value-for-query`,
      null
    ) as Observable<DefaultAcousticParameterValuesDto>;
  }

  getDeletableConversationCount(filters: FilterItemDto[]): Observable<number> {
    let params = new HttpParams();
    params = params.append('filters', JSON.stringify(filters));

    return this.http.get(`${this.apiBase}/api/app/conversation/deletable-count`, {
      params,
    }) as Observable<number>;
  }

  deleteConversations(filters: ConversationCleanerFilterDto): Observable<any> {
    return this.http.post(
      `${this.apiBase}/api/app/conversation/delete-request`,
      filters
    ) as Observable<any>;
  }

  getReanalyzableConversationCount(tenantId: string, filters: FilterItemDto[]): Observable<number> {
    let params = new HttpParams();
    params = params.append('tenantId', tenantId);
    params = params.append('filters', JSON.stringify(filters));

    return this.http.get(`${this.apiBase}/api/app/conversation/reanalyzable-count`, {
      params,
    }) as Observable<number>;
  }

  reanalyzableConversations(input: ReanalyzeConversationRequestDto): Observable<number> {
    return this.http.post(
      `${this.apiBase}/api/app/conversation/reanalyze-request`,
      input
    ) as Observable<any>;
  }

  createConversationResult(
    input: ConversationsResultInputDto
  ): Observable<ConversationsResultOutputDto> {
    const apiUrl = `${this.apiBase}/api/app/conversation/result`;
    return this.http.post(apiUrl, input) as Observable<ConversationsResultOutputDto>;
  }

  getConversationResult(input: string): Observable<ConversationsResultInputDto> {
    const apiUrl = `${this.apiBase}/api/app/conversation/result/` + input;
    return this.http.get(apiUrl) as Observable<ConversationsResultInputDto>;
  }

  getConversationStates(id: number): Observable<ConversationStateMachineStates> {
    const apiUrl = `${this.apiBase}/api/app/conversation/${id}/states`;
    return this.http.get(apiUrl) as Observable<ConversationStateMachineStates>;
  }

  getExcludedConversationListTypes(): number[] {
    let result = [];
    if (!this.featureService.isEnabled(FeatureConstants.CallType)) {
      result.push(ConversationListType.call);
    }
    if (!this.featureService.isEnabled(FeatureConstants.ChatType)) {
      result.push(ConversationListType.chat);
    }
    if (!this.featureService.isEnabled(FeatureConstants.MeetingType)) {
      result.push(ConversationListType.meeting);
    }
    if (!this.featureService.isEnabled(FeatureConstants.VideoCallType)) {
      result.push(ConversationListType.videoCall);
    }
    if (!this.featureService.isEnabled(FeatureConstants.EmailType)) {
      result.push(ConversationListType.email);
    }
    return result;
  }

  getDefaultConversationListTypes(): number[] {
    let result = [];

    if (this.featureService.isEnabled(FeatureConstants.CallType)) {
      result.push(ConversationListType.call);
    }

    if (this.featureService.isEnabled(FeatureConstants.ChatType)) {
      result.push(ConversationListType.chat);
    }

    if (result.length === 0) {
      if (this.featureService.isEnabled(FeatureConstants.MeetingType)) {
        result.push(ConversationListType.meeting);
      } else if (this.featureService.isEnabled(FeatureConstants.VideoCallType)) {
        result.push(ConversationListType.videoCall);
      } else if (this.featureService.isEnabled(FeatureConstants.EmailType)) {
        result.push(ConversationListType.email);
      }
    }

    return result;
  }

  getEnabledConversationTypes(conversationTypes: GenericLookupDto[]): GenericLookupDto[] {
    if (!this.featureService.isEnabled(FeatureConstants.CallType)) {
      conversationTypes = conversationTypes.filter(f => f.id !== ConversationType.call);
    }
    if (!this.featureService.isEnabled(FeatureConstants.ChatType)) {
      conversationTypes = conversationTypes.filter(f => f.id !== ConversationType.chat);
    }
    if (!this.featureService.isEnabled(FeatureConstants.MeetingType)) {
      conversationTypes = conversationTypes.filter(f => f.id !== ConversationType.meeting);
    }
    if (!this.featureService.isEnabled(FeatureConstants.VideoCallType)) {
      conversationTypes = conversationTypes.filter(f => f.id !== ConversationType.videoCall);
    }
    if (!this.featureService.isEnabled(FeatureConstants.EmailType)) {
      conversationTypes = conversationTypes.filter(f => f.id !== ConversationType.email);
    }
    return conversationTypes;
  }

  getDefaultConversationTypeId(): number {
    if (this.featureService.isEnabled(FeatureConstants.CallType)) {
      return ConversationType.call;
    }

    if (this.featureService.isEnabled(FeatureConstants.ChatType)) {
      return ConversationType.chat;
    }

    if (this.featureService.isEnabled(FeatureConstants.MeetingType)) {
      return ConversationType.meeting;
    }

    if (this.featureService.isEnabled(FeatureConstants.VideoCallType)) {
      return ConversationType.videoCall;
    }

    if (this.featureService.isEnabled(FeatureConstants.EmailType)) {
      return ConversationType.email;
    }

    return ConversationType.all;
  }

  checkAndAddAutomaticEvaluationScoreColumn(columnDefinitions: MatTableColumnDefinitionModel[]) {
    let showAutomaticEvaluationScoreEnabled =
      this.config
        .getSetting('QualityManagement.AutomaticEvaluation.ShowAutomaticEvaluationScore')
        .toLowerCase() === 'true'
        ? true
        : false;
    let qualityManagementFeatureEnabled = this.featureService.isEnabled(
      FeatureConstants.QualityManagement
    );

    if (qualityManagementFeatureEnabled && showAutomaticEvaluationScoreEnabled) {
      columnDefinitions.push({
        columnName: 'automaticEvaluationScore',
        header: this.localizationService.instant('QualityManagement::Score'),
        binding: 'automaticEvaluationScore',
        valueParser: undefined,
        pipes: [
          {
            pipe: DecimalPipe,
            args: ['1.2-2'],
          },
        ],
        width: '90px',
        tooltip: this.localizationService.instant('::AutomaticEvaluationScoreInformation'),
        sortable: true,
        sorterFieldName: 'AutomaticEvaluationScore',
        headerClass: 'header-align-right',
        cellClass: 'cell-align-right',
        visible: true,
        locked: false,
      });
    }
  }

  taggingConversations(data: FormData): Observable<any> {
    return this.http.post(
      `${this.apiBase}/api/app/conversation/batch-tagging`,
      data
    ) as Observable<any>;
  }
}
